<template>
  <div v-if="$role(['director', 'service_advisor', 'head_operation', 'logistic'], $store.state.user.role)">
    <v-row no-gutters>
      <v-col cols="12" class="d-flex jutify-space-between col-sm-2 py-4">
        <v-card :class="$role(['logistic'], $store.state.user.role) ? 'primary' : 'grey'" class="d-flex align-center justify-center px-4 fill-width caption font-weight-bold py-2" dark outlined @click.prevent="$role(['logistic'], $store.state.user.role) ? PURCHASE_SHOW() : null">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          New Purchase
        </v-card>
      </v-col>
      <v-col cols="12" class="d-flex jutify-space-between col-sm-2 py-4 px-2">
        <v-card :class="$role(['logistic'], $store.state.user.role) ? 'green' : 'grey'" class="d-flex align-center justify-center px-4 caption font-weight-bold mr-1 py-2" dark outlined style="width: 49%;" @click.prevent="$role(['logistic'], $store.state.user.role) ? ExportExcel(true) : false">
          <v-icon small class="mr-2">
            mdi-microsoft-excel
          </v-icon>
          Export
        </v-card>
        <v-card :class="$role(['logistic'], $store.state.user.role) ? 'green' : 'grey'" class="d-flex align-center justify-center px-4 caption font-weight-bold ml-1 py-2" dark outlined style="width: 49%;" @click.prevent="$role(['logistic'], $store.state.user.role) ? ExportExcel('all') : false">
          <v-icon small class="mr-2">
            mdi-microsoft-excel
          </v-icon>
          All
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-3">
        <div class="px-4 pt-4 pb-0 pb-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            placeholder="Search ..."
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-3">
        <div class="px-2 pt-4 pb-0 pb-sm-4">
          <v-menu
            ref="menu"
            v-model="menu"
            :return-value.sync="dates"
            left
            offset-y
            :close-on-content-click="false"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateRangeText"
                label="Date Ranges"
                append-icon="mdi-calendar-outline"
                readonly
                outlined
                dense
                hide-details
                clearable
                v-bind="attrs"
                v-on="on"
                @click:clear="dates = [], PURCHASE_GET(tableSearch)"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              light
              class="force-light-font"
              range
            >
              <v-spacer />
              <v-btn
                text
                class="text-capitalize"
                small
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                :disabled="dates.length !== 2"
                text
                class="text-capitalize"
                small
                @click="$refs.menu.save(dates), PURCHASE_GET(tableSearch)"
              >
                Ok
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-2">
        <div class="px-4 pt-4 pb-0 pb-sm-4">
          <v-select
            v-model.number="tableSearchStatus"
            :items="[
              { name: 'All Status', value: -1 },
              { name: 'Pending', value: 0 },
              { name: 'Waiting Confirmation', value: 1 },
              { name: 'Processed', value: 2 },
              { name: 'Received', value: 3 },
              { name: 'Closed', value: 4 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            label="Purchase Status"
          />
        </div>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="py-1">
              RDMS/PO/{{ item.id }}
              <div class="caption">
                {{ item.title || '-' }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.supplier_name`]="{ item }">
            <div>
              {{ item.supplier_name }}
              <a :href="'https://api.whatsapp.com/send?phone=' + item.supplier_phone + '&text=Halo'" target="_blank" class="d-flex fill-width align-center green--text" style="text-decoration: none;">
                <v-icon class="mr-1" small color="green">
                  mdi-whatsapp
                </v-icon>
                {{ item.supplier_phone || '-' }}
              </a>
            </div>
          </template>
          <template v-slot:[`item.total_price`]="{ item }">
            <div>
              {{ $price(item.total_price) }}
            </div>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            <div>
              {{ $localDT(item.created, 'daydate') }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div>
              {{ StatusPO(parseInt(item.status)) }}
            </div>
          </template>
          <template v-slot:[`item.total_item`]="{ item }">
            <div>
              {{ item.total_item }} Items
              <div class="d-flex fill-width justify-space-between caption">
                Stock Progress : {{ (parseInt(item.total_quantity_in) || 0) }}/{{ (parseInt(item.total_quantity) || 0) }}
                <v-chip v-if="parseInt(item.status) >= 3" :color="$role(['director', 'logistic'], $store.state.user.role) ? 'purple' : 'grey'" x-small dark @click.prevent="$role(['director', 'logistic'], $store.state.user.role) ? (ShowStockInHistory(item, (parseInt(item.total_quantity) || 0) - (parseInt(item.total_quantity_in) || 0))) : false">
                  {{ parseFloat((parseInt(item.total_quantity_in) || 0) * 100/(parseInt(item.total_quantity) || 0)).toFixed(1) }}%
                  <v-icon x-small class="ml-1">
                    mdi-list-box-outline
                  </v-icon>
                </v-chip>
              </div>
            </div>
          </template>
          <template v-slot:[`item.payment_type`]="{ item }">
            <div class="d-flex align-center">
              <div class="py-1">
                {{ parseInt(item.payment_type) ? ('Recurring ' + (item.payment_recurring) + 'x') : 'One time payment' }}
                <div v-if="!parseInt(item.payment_type)" class="caption">
                  <div>
                    Paid {{ $price(item.total_price_paid || 0) }}
                  </div>
                </div>
                <div v-else class="caption">
                  <div>
                    Paid {{ $price(item.total_price_paid || 0) }}
                  </div>
                  <div class="d-flex align-center">
                    <v-icon class="mr-1" small>
                      mdi-calendar-sync
                    </v-icon>
                    {{ $localDT(item.payment_duedate, 'datedefault') }}
                  </div>
                </div>
              </div>
              <v-icon v-if="parseInt(item.status) > 1" :color="$role(['director', 'logistic'], $store.state.user.role) ? 'primary' : 'grey'" class="ml-4" @click.prevent="$role(['director', 'logistic'], $store.state.user.role) ? ShowPayments(item.id, item.supplier, ((parseInt(item.total_price) || 0) - (parseInt(item.total_price_paid) || 0))) : false">
                mdi-list-box-outline
              </v-icon>
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn depressed small dark class="rounded text-capitalize mr-2" @click.prevent="ShowPrint(item)">
                <v-icon small class="mr-2">
                  mdi-printer
                </v-icon>
                Print
              </v-btn>
              <v-btn v-if="$role(['director', 'logistic'], $store.state.user.role)" :color="$role(['director', 'logistic'], $store.state.user.role) ? 'orange' : 'grey'" depressed small dark class="rounded text-capitalize" @click.prevent="$role(['director', 'logistic'], $store.state.user.role) ? PURCHASE_SHOW(item) : false">
                <v-icon small class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogPurchase.show"
      persistent
      scrollable
      max-width="550"
    >
      <v-card v-if="dialogPurchase.selected" >
        <v-card-title class="body-1 justify-center">
          {{ dialogPurchase.selected.id ? 'Update Purchase' : 'Create New Purchase' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: calc(100vh - 150px);">
          <v-autocomplete
            v-model.number="dialogPurchase.selected.supplier"
            :disabled="parseInt(dialogPurchase.selected.status) > 1"
            :items="supplierData"
            item-text="fullname"
            item-value="id"
            outlined
            dense
            clearable
            hide-details
            label="Select supplier"
            class="mb-4"
            @click:clear="dialogPurchase.selected.supplier = 1"
          >
            <template v-slot:append-outer>
              <v-slide-x-reverse-transition
                mode="out-in"
              >
                <v-icon
                  v-text="SupplierSelected ? 'mdi-pencil-circle' : 'mdi-plus-circle'"
                  @click="parseInt(dialogPurchase.selected.status) > 1 ? false : parseInt(dialogPurchase.selected.status) > 1 ? (SupplierSelected ? SUPPLIER_SHOW(SupplierSelected) : SUPPLIER_SHOW()) : ''"
                />
              </v-slide-x-reverse-transition>
            </template>
          </v-autocomplete>
          <div v-if="parseInt(dialogPurchase.selected.supplier)" class="px-3 py-2 black--text rounded-lg grey lighten-4" v-html="SupplierSelected ? (SupplierSelected.fullname + '<br>' + SupplierSelected.address) : ''" />
          <v-divider class="my-4" />
          <div>
            <v-autocomplete
              v-model="productData.selected"
              :disabled="parseInt(dialogPurchase.selected.status) > 1"
              :search-input.sync="productData.query"
              :items="productData.result"
              item-text="item_fullname"
              outlined
              dense
              hide-details
              return-object
              clearable
              class="mb-4"
              label="Add items ..."
            />
          </div>
          <div>
            <div v-for="(si, iSi) in dialogPurchase.selected.items" :key="'purchased-item-' + iSi" class="my-2">
              <div class="d-flex fill-width justify-space-between black--text pb-2">
                {{ iSi + 1 }}. {{ si.item_fullname }}
                <v-chip color="purple" x-small dark>
                  Stock In {{ parseFloat((parseInt(si.total_quantity_in) || 0)*100/(parseInt(si.quantity) || 0)).toFixed(1) }}%
                </v-chip>
              </div>
              <div class="d-flex flex-column flex-sm-row justify-space-between align-center fill-width">
                <div style="max-width: calc(100% - 120px) !important">
                  <v-row no-gutters>
                    <v-col cols="12" class="col-sm-6">
                      <v-text-field
                        v-model.number="dialogPurchase.selected.items[iSi].price"
                        :disabled="parseInt(dialogPurchase.selected.status) > 1"
                        outlined
                        dense
                        hide-details
                        label="Price (by latest completed purchase)"
                        prefix="Rp"
                        @keypress="$NumOnly"
                      />
                    </v-col>
                    <v-col cols="12" class="col-sm-6 pl-0 pl-sm-2">
                      <v-text-field
                        v-model.number="dialogPurchase.selected.items[iSi].quantity"
                        :disabled="parseInt(dialogPurchase.selected.status) > 1"
                        outlined
                        dense
                        hide-details
                        label="Qty"
                        @keypress="$NumOnly"
                      />
                    </v-col>
                  </v-row>
                </div>
                <div class="black--text body-2">
                  {{ $price(parseInt(si.price) * (parseInt(si.quantity) || 1)) }}
                </div>
              </div>
            </div>
            <v-divider class="my-4" />
            <v-text-field
              v-model="dialogPurchase.selected.title"
              outlined
              dense
              hide-details
              class="mb-4"
              label="Purchase notes"
            />
            <v-select
              v-model.number="dialogPurchase.selected.status"
              :items="$store.state.user.role === 'logistic' && parseInt(dialogPurchase.statusOld) < 2 ? [
                { name: 'Pending', value: 0 },
                { name: 'Waiting Confirmation', value: 1 },
                { name: 'Closed', value: 4 }
              ] : [
                { name: 'Pending', value: 0 },
                { name: 'Waiting Confirmation', value: 1 },
                { name: 'Processed', value: 2 },
                { name: 'Received', value: 3 },
                { name: 'Closed', value: 4 }
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
              class="mb-4"
              label="Purchase Status"
            />
            <v-divider class="mb-4" />
            <div class="pa-4 primary title rounded-lg font-weight-bold white--text fill-width text-center mb-4">
              {{ $price(TotalPurchase) }}
            </div>
            <v-divider class="my-4" />
            <v-select
              v-model.number="dialogPurchase.selected.payment_type"
              :disabled="parseInt(dialogPurchase.selected.status) > 1"
              :items="[
                { name: 'One Time Payment', value: 0 },
                { name: 'Recurring', value: 1 }
              ]"
              item-text="name"
              item-value="value"
              outlined
              dense
              hide-details
              class="mb-4"
              label="Payment Type"
            />
            <v-menu
              v-if="parseInt(dialogPurchase.selected.payment_type)"
              ref="menuRecurring"
              v-model="menuRecurring"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dialogPurchase.selected.payment_date"
                  :disabled="parseInt(dialogPurchase.selected.status) > 1"
                  readonly
                  outlined
                  dense
                  hide-details
                  prepend-icon="mdi-calendar-sync"
                  class="mb-4"
                  label="Payment Due Date"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="dialogPurchase.selected.payment_date"
                :disabled="parseInt(dialogPurchase.selected.status) > 1"
                no-title
                scrollable
                @input="menuRecurring = false"
              />
            </v-menu>
            <v-row v-if="parseInt(dialogPurchase.selected.payment_type)" no-gutters>
              <v-col cols="12" class="col-sm-6 pr-0 pr-sm-1">
                <v-text-field
                  v-model.number="dialogPurchase.selected.payment_recurring"
                  :disabled="parseInt(dialogPurchase.selected.status) > 1"
                  outlined
                  dense
                  hide-details
                  label="Recurring"
                  append-icon="mdi-close"
                  @keypress="$NumOnly"
                />
              </v-col>
              <v-col cols="12" class="col-sm-6 pl-0 pl-sm-1">
                <v-text-field
                  :value="$price(parseInt(TotalPurchase) / (parseInt(dialogPurchase.selected.payment_recurring) || 1))"
                  :disabled="parseInt(dialogPurchase.selected.status) > 1"
                  readonly
                  outlined
                  dense
                  hide-details
                  label="Amount"
                  @keypress="$NumOnly"
                />
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red"
            text
            dark
            class="text-capitalize"
            @click="dialogPurchase.show = false, PURCHASE_GET(tableSearch)"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="parseInt(dialogPurchase.selected) >= 2 || !parseInt(dialogPurchase.selected.supplier) || !dialogPurchase.selected.items.length"
            color="primary"
            depressed
            class="text-capitalize"
            @click="PURCHASE_PROCESS()"
          >
            {{ parseInt(dialogPurchase.selected.id) ? 'Update' : 'Create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogSupplier.show"
      persistent
      scrollable
      max-width="375"
    >
      <v-card v-if="dialogSupplier.data">
        <v-card-title class="body-1 justify-center">
          {{ dialogSupplier.data.id ? 'Add' : 'Update' }} Supplier
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <v-text-field
            v-model="dialogSupplier.data.name"
            outlined
            dense
            hide-details
            label="Supplier Name"
            class="mb-4 rounded-0"
          />
          <v-text-field
            v-model="dialogSupplier.data.email"
            outlined
            dense
            hide-details
            label="Email"
            class="mb-4 rounded-0"
          />
          <v-text-field
            v-model="dialogSupplier.data.phone"
            outlined
            dense
            hide-details
            label="Phone/Whatsapp"
            class="mb-4 rounded-0"
            @keypress="$NumOnly"
          />
          <v-textarea
            v-model="dialogSupplier.data.address"
            outlined
            rows="2"
            dense
            hide-details
            label="Address"
            class="mb-4 rounded-0"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="dialogSupplier.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="SUPPLIER_PROCESS()"
          >
            Process
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogPayments.show"
      persistent
      scrollable
      max-width="375"
    >
      <v-card>
        <v-card-title class="body-1 justify-center">
          Payment History
        </v-card-title>
        <v-divider />
        <v-card-text style="max-height: 300px;" class="px-0 pb-0">
          <v-row no-gutters class="pt-4 grey lighten-4 px-4">
            <v-col cols="12" class="col-sm-6 pr-0 pr-sm-1">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dialogPayments.newPayment.payment_date"
                    readonly
                    outlined
                    dense
                    hide-details
                    label="Payment Date"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="dialogPayments.newPayment.payment_date"
                  no-title
                  scrollable
                />
              </v-menu>
            </v-col>
            <v-col cols="12" class="col-sm-6 pl-0 pl-sm-1">
              <v-text-field
                v-model.number="dialogPayments.newPayment.amount"
                outlined
                dense
                hide-details
                label="Amount"
                prefix="Rp"
                @keypress="$NumOnly"
              />
            </v-col>
            <v-col cols="12" class="d-flex py-4">
              <v-text-field
                v-model="dialogPayments.newPayment.notes"
                outlined
                dense
                hide-details
                label="Notes"
                class="outer-btn"
                prepend-inner-icon="mdi-note-edit-outline"
              >
                <template v-slot:append-outer>
                  <v-btn fab depressed x-small color="primary">
                    <v-icon
                      @click="ProcessPayment()"
                    >
                      mdi-arrow-right-circle
                    </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-divider class="mb-4" />
          <div v-if="dialogPayments.loading" class="pa-4 text-center">
            Loading data ...
          </div>
          <v-card outlined v-for="(pl, iPl) in dialogPayments.data" :key="'payment-list-' + iPl" class="px-3 py-2 my-1 rounded-0">
            <div class="fill-width d-flex justify-space-between">
              {{ $localDT(pl.created, 'datedefault') }}
              <span>
                {{ $price(pl.amount) }}
              </span>
            </div>
            <div class="caption grey--text text--darken-2">
              {{ pl.notes || '-' }}
            </div>
          </v-card>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            class="text-capitalize"
            @click="dialogPayments.show = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogStockInHistory.show"
      persistent
      scrollable
      max-width="475"
    >
      <v-card>
        <v-card-title class="body-1 justify-center">
          Stock In History
        </v-card-title>
        <v-divider />
        <v-card-text style="max-height: 300px;" class="px-0 pb-0">
          <v-row no-gutters class="pt-4 grey lighten-4 px-4">
            <v-col cols="12" class="col-sm-6">
              <v-select
                v-model.number="dialogStockInHistory.new.product_stock"
                :items="dialogStockInHistory.product_stock_data.filter(r => (parseInt(r.total_quantity_in) || 0) < parseInt(r.quantity))"
                item-text="item_fullname"
                item-value="id"
                outlined
                dense
                hide-details
                label="Select Item"
              />
            </v-col>
            <v-col cols="12" class="col-sm-3 px-0 px-sm-1">
              <v-text-field
                v-model.number="dialogStockInHistory.new.quantity"
                outlined
                dense
                hide-details
                label="Qty IN"
                @keypress="$NumOnly"
              />
            </v-col>
            <v-col cols="12" class="col-sm-3">
              <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dialogStockInHistory.new.stock_date"
                    readonly
                    outlined
                    dense
                    hide-details
                    label="Date"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="dialogStockInHistory.new.stock_date"
                  no-title
                  scrollable
                />
              </v-menu>
            </v-col>
            <v-col cols="12" class="d-flex py-4">
              <v-text-field
                v-model="dialogStockInHistory.new.notes"
                outlined
                dense
                hide-details
                label="Notes"
                class="outer-btn"
                prepend-inner-icon="mdi-note-edit-outline"
              >
                <template v-slot:append-outer>
                  <v-btn fab depressed x-small color="primary">
                    <v-icon
                      @click="ProcessStockInHistory()"
                    >
                      mdi-arrow-right-circle
                    </v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-divider class="mb-4" />
          <div v-if="dialogStockInHistory.loading" class="pa-4 text-center">
            Loading data ...
          </div>
          <v-card outlined v-for="(pl, iPl) in dialogStockInHistory.data" :key="'stock-in-' + iPl" class="px-3 py-2 my-1 rounded-0">
            <div class="fill-width d-flex justify-space-between">
              <span>{{ $localDT(pl.stock_date, 'datedefault') }}, {{ pl.item_fullname || '' }}</span>
              <span>
                {{ pl.quantity }}
              </span>
            </div>
            <div class="caption grey--text text--darken-2">
              {{ pl.notes || '-' }}
            </div>
          </v-card>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            class="text-capitalize"
            @click="dialogStockInHistory.show = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogPrint.show"
      width="750"
      class="xxx"
      content-class="dialog-printed black--text"
    >
      <v-card v-if="dialogPrint.detail">
        <v-card-text class="pb-4 caption black--text" style="letter-spacing:0px!important;">
          <div>
            <div class="d-flex fill-width justify-end mb-2" style="overflow: hidden;height: 100px;">
              <img src="/icon-clean.png" style="height: 155px;margin-top: -20px;">
            </div>
            <v-divider class="mb-2" />
            <div class="font-weight-bold title d-flex justify-center text-uppercase">
              PURCHASE ORDER
            </div>
          </div>
          <v-divider class="my-2" />
          <div class="black--text">
            <div class="d-flex fill-width align-start justify-space-between">
              <div class="d-flex flex-column justify-center text-uppercase pr-1" style="max-width: 50%!important;">
                <span class="fill-width">
                  <span class="font-weight-bold d-inline-block" style="min-width: 75px;">Supplier</span>: {{ SupplierDetail ? SupplierDetail.name : '-' }}
                </span>
                <span class="fill-width">
                  <span class="font-weight-bold d-inline-block" style="min-width: 75px;">Alamat</span>: {{ SupplierDetail ? SupplierDetail.address : '-' }}
                </span>
                <span class="fill-width">
                  <span class="font-weight-bold d-inline-block" style="min-width: 75px;">No Telepon</span>: {{ SupplierDetail ? SupplierDetail.phone : '-' }}
                </span>
              </div>
              <div class="d-flex flex-column justify-center text-uppercase pl-1" style="max-width: 50%!important;">
                <span class="fill-width">
                  <span class="font-weight-bold d-inline-block pr-1" style="min-width: 75px;">No</span>: RDMS/PO/{{ dialogPrint.detail.id }}
                </span>
                <span class="fill-width">
                  <span class="font-weight-bold d-inline-block pr-1" style="min-width: 75px;">Status</span>: {{ StatusPO(parseInt(dialogPrint.detail.status)) }}
                </span>
                <span class="fill-width">
                  <span class="font-weight-bold d-inline-block pr-1" style="min-width: 75px;">Tanggal</span>: {{ $localDT(dialogPrint.detail.created, 'datelocal') }}
                </span>
              </div>
            </div>
          </div>
          <v-divider class="my-2" />
          <table class="fill-width table-print">
            <thead>
              <tr class="font-weight-bold">
                <td class="text-center pa-1">No</td>
                <td class="text-center pa-1">Item</td>
                <td class="text-center pa-1">Qty.</td>
                <td class="text-center pa-1">Harga</td>
                <td class="text-center pa-1">Jumlah</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(i, iI) in dialogPrint.detail.items" :key="'dialog-print-item-' + iI" class="">
                <td class="text-center pa-1">{{ iI + 1 }}</td>
                <td class="pa-1">{{ i.item_fullname }}</td>
                <td class="text-center pa-1">{{ i.quantity }}</td>
                <td class="text-right pa-1">{{ $price(i.price) }}</td>
                <td class="text-right pa-1">{{ $price((parseInt(i.price) || 0) * (parseInt(i.quantity) || 0)) }}</td>
              </tr>
              <tr class="font-weight-bold">
                <td class="pa-1" colspan="4">Total</td>
                <td class="text-right pa-1">{{ $price(TotalPurchasePrint) }}</td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-divider />
        <div class="d-flex justify-end fill-width py-8">
          <div class="text-center px-2" style="min-width: 200px;">
            Hormat Kami<br><br><br>
            ( ......................... )
          </div>
        </div>
        <v-divider class="no-print" />
        <v-card-actions class="no-print">
          <v-btn
            color="red"
            text
            small
            class="text-capitalize"
            @click="dialogPrint.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            dark
            small
            class="text-capitalize"
            @click="Print()"
          >
            <v-icon small color="white" class="mr-1">
              mdi-print
            </v-icon>
            Print
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
let searchProductTimeout = null
export default {
  name: 'Logistik',
  data: () => ({
    dialogPrint: {
      show: false,
      data: null,
      detail: null,
      statusOld: 0
    },
    menuRecurring: false,
    headers: [
      {
        text: 'ID',
        value: 'id',
        sortable: false
      },
      { text: 'Supplier', value: 'supplier_name', sortable: true },
      { text: 'Items', value: 'total_item', sortable: true },
      { text: 'Created', value: 'created', sortable: true },
      { text: 'Status', value: 'status', sortable: true },
      { text: 'Amount', value: 'total_price', sortable: true },
      { text: 'Payment', value: 'payment_type', sortable: true },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearchStatus: -1,
    tableSearch: '',
    tableLoading: false,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    options: {},
    dialogPurchase: {
      show: false,
      statusOld: 0,
      selected: null
    },
    dialogSupplier: {
      show: false,
      data: null
    },
    supplierData: [],
    productData: {
      query: '',
      result: [],
      selected: null
    },
    menu: false,
    dates: [],
    dialogPayments: {
      id: 0,
      supplier: 0,
      show: false,
      loading: false,
      data: [],
      newPayment: {
        payment_date: null,
        amount: 0,
        notes: ''
      }
    },
    dialogStockInHistory: {
      detail: null,
      show: false,
      loading: false,
      data: [],
      product_stock_data: [],
      new: {
        stock_date: null,
        quantity: 0,
        notes: '',
        product_stock: 0
      }
    }
  }),
  computed: {
    TotalPurchasePrint () {
      let total = 0
      if (this.dialogPrint.detail) {
        const items = this.dialogPrint.detail.items || []
        if (items.length) {
          for (let x = 0; x < items.length; x++) {
            total += (parseInt(items[x].price) || 0) * (parseInt(items[x].quantity) || 1)
          }
        }
      }
      return total
    },
    TotalPurchase () {
      let total = 0
      if (this.dialogPurchase.selected) {
        const items = this.dialogPurchase.selected.items || []
        if (items.length) {
          for (let x = 0; x < items.length; x++) {
            total += (parseInt(items[x].price) || 0) * (parseInt(items[x].quantity) || 1)
          }
        }
      }
      return total
    },
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    SupplierSelected () {
      if (this.dialogPurchase.show && this.dialogPurchase.selected) {
        const supplierID = parseInt(this.dialogPurchase.selected.supplier) || 0
        if (supplierID) {
          const findSupplier = this.supplierData.find(r => parseInt(r.id) === supplierID)
          return findSupplier
        }
      }
      return null
    },
    SupplierDetail () {
      if (!this.dialogPrint.detail) {
        return null
      }
      return this.supplierData.find(r => parseInt(r.id) === parseInt(this.dialogPrint.detail.supplier))
    }
  },
  watch: {
    'dialogStockInHistory.new.product_stock' (v) {
      if (parseInt(v)) {
        this.UpdateQuantityMax()
      }
    },
    tableSearchStatus () {
      this.PURCHASE_GET(this.tableSearch)
    },
    'productData.selected' (v) {
      if (v) {
        if (v.id) {
          if (this.dialogPurchase.selected) {
            if (this.dialogPurchase.selected.items) {
              const itemExist = this.dialogPurchase.selected.items.find(r => parseInt(r.product_spec) === parseInt(v.id))
              if (itemExist) {
                this.$store.dispatch('TOAST', { show: true, message: 'Item already added!' })
              } else {
                const newItem = Object.assign({}, {
                  id: null,
                  stock_request: parseInt(this.dialogPurchase.selected.id),
                  product_spec: parseInt(v.id),
                  price: parseInt(v.price_initial_updated) || parseInt(v.price_initial),
                  quantity: 1,
                  item_fullname: v.item_fullname
                })
                this.dialogPurchase.selected.items.push(newItem)
                this.productData.selected = null
                this.productData.query = ''
              }
            }
          }
        }
      }
    },
    'productData.query' (v, vBefore) {
      this.productData.result = []
      if (v) {
        if (searchProductTimeout) {
          clearTimeout(searchProductTimeout)
        }
        searchProductTimeout = setTimeout(() => {
          this.PRODUCT_GET(v)
        }, 300)
      }
    },
    dates (v) {
      // if (v.length === 2) {
      //   this.PURCHASE_GET(this.tableSearch)
      // }
      // else {
      //   this.PURCHASE_GET(this.tableSearch)
      // }
    },
    tableSearch: function (q) {
      // if (searchInterval) {
      //   clearTimeout(searchInterval)
      // }
      // searchInterval = setTimeout(() => {
      //   this.PURCHASE_GET(q)
      // }, 300)
    },
    options: {
      handler () {
        if (!this.tableLoading) {
          this.PURCHASE_GET(this.tableSearch)
        }
      },
      deep: true
    }
  },
  created () {
    this.dates = [this.$localDT(new Date(), 'datedefault'), this.$localDT(new Date(), 'datedefault')]
    this.SUPPLIER_GET()
  },
  methods: {
    ShowPrint (item) {
      this.dialogPrint.data = Object.assign({}, item)
      this.$store.dispatch('product/STOCK_REQUEST_GET_ONE', item.id)
        .then((res) => {
          console.log(res)
          if (res.status) {
            if (res.data.data) {
              this.dialogPrint.detail = Object.assign({}, res.data.data)
              this.dialogPrint.statusOld = parseInt(item.status) || 0
            }
          }
        })
      if (!this.supplierData.length) {
        this.SUPPLIER_GET()
      }
      this.dialogPrint.show = true
    },
    Print () {
      window.print()
    },
    async ExportExcel (isAll) {
      if (!isAll) {
        return false
      }
      const requestData = await this.PURCHASE_GET(this.tableSearch, isAll)
      if (requestData && requestData.status && requestData.data.data.data) {
        const headers = ['ID', 'SUPPLIER NAME', 'ADDRESS', 'PHONE', 'EMAIL', 'ITEMS', 'AMOUNT', 'STATUS', 'CREATED', 'NOTES']
        const rows = []
        const data = requestData.data.data.data || []
        for (let d = 0; d < data.length; d++) {
          const newRow = data[d]
          rows.push([
            {
              type: Number,
              value: newRow.id
            },
            {
              type: String,
              value: (newRow.supplier_name || '-')
            },
            {
              type: String,
              value: (newRow.supplier_address || '-')
            },
            {
              type: String,
              value: (newRow.supplier_phone || '-')
            },
            {
              type: String,
              value: (newRow.supplier_email || '-')
            },
            {
              type: Number,
              value: (parseInt(newRow.total_item) || 0)
            },
            {
              type: Number,
              value: parseFloat(newRow.total_price) || 0
            },
            {
              type: String,
              value: this.StatusPO(parseInt(newRow.status))
            },
            {
              type: Date,
              format: 'yyyy-mm-dd hh:mm:ss',
              value: new Date(newRow.created)
            },
            {
              type: String,
              value: newRow.title || '-'
            }
          ])
        }
        await this.$CreateExcelFile({
          headers: headers,
          rows: rows
        }, 'po-' + (isAll === 'all' ? 'all-' : 'filtered-') + this.$localDT(null, 'datedefault') + '.xlsx')
      }
    },
    UpdateQuantityMax () {
      const index = this.dialogStockInHistory.product_stock_data.findIndex(r => parseInt(r.id) === parseInt(this.dialogStockInHistory.new.product_stock))
      if (index > -1) {
        this.dialogStockInHistory.new.quantity = (parseInt(this.dialogStockInHistory.product_stock_data[index].quantity) || 0) - (parseInt(this.dialogStockInHistory.product_stock_data[index].total_quantity_in) || 0)
      } else {
        this.dialogStockInHistory.new.quantity = 0
      }
    },
    StatusPO (st) {
      if (st === 0) {
        return 'Pending'
      } else if (st === 1) {
        return 'Waiting'
      } else if (st === 2) {
        return 'Proses'
      } else if (st === 3) {
        return 'Received'
      } else if (st === 4) {
        return 'Closed'
      }
      return '?'
    },
    async ShowStockInHistory (item, remainingQuantity) {
      this.dialogStockInHistory.detail = Object.assign({}, item)
      this.dialogStockInHistory.data = []
      this.dialogStockInHistory.loading = true
      this.dialogStockInHistory.show = true
      // this.dialogStockInHistory.new.quantity = remainingQuantity || 0
      this.dialogStockInHistory.new.quantity = 0
      this.dialogStockInHistory.new.stock_date = this.$localDT(null, 'datedefault')
      this.dialogStockInHistory.new.product_stock = 0
      this.dialogStockInHistory.new.notes = null
      await this.$store.dispatch('product/STOCK_REQUEST_GET_ONE', item.id)
        .then((res) => {
          if (res.status) {
            if (res.data.data) {
              const result = res.data.data
              this.dialogStockInHistory.product_stock_data = result.items || []
            }
          }
        })
      await this.LoadStockInHistory(item.id)
    },
    async LoadStockInHistory (stockRequestID) {
      this.$store.dispatch('product/STOCK_REQUEST_IN_HISTORY_GET', stockRequestID)
        .then((res) => {
          this.dialogStockInHistory.loading = false
          if (res.status) {
            this.dialogStockInHistory.data = res.data.data || []
          }
        })
    },
    ProcessStockInHistory () {
      this.dialogStockInHistory.loading = true
      const dataHistory = Object.assign({}, this.dialogStockInHistory)
      if (!dataHistory.new.stock_date) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid Date!' })
        return false
      }
      if (!parseInt(dataHistory.new.quantity)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid Quantity!' })
        return false
      }
      if (!parseInt(dataHistory.new.product_stock)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please select item!' })
        return false
      }
      if (!confirm('Add Stock In ?')) {
        return false
      }
      this.$store.dispatch('product/STOCK_REQUEST_IN_HISTORY_PROCESS', {
        stock_request: dataHistory.detail.id,
        stock_date: dataHistory.new.stock_date,
        quantity: dataHistory.new.quantity,
        notes: dataHistory.new.notes,
        product_stock: dataHistory.new.product_stock
      })
        .then((res) => {
          if (res.status) {
            this.dialogStockInHistory.data = res.data.data || []
            this.dialogStockInHistory.new.quantity = 0
            this.dialogStockInHistory.new.product_stock = 0
            this.dialogStockInHistory.new.notes = ''
            this.dialogStockInHistory.loading = false
            this.LoadStockInHistory(dataHistory.detail.id)
            this.$store.dispatch('product/STOCK_REQUEST_GET_ONE', dataHistory.detail.id)
              .then((rr) => {
                if (rr.status) {
                  if (rr.data.data) {
                    const rrr = rr.data.data
                    this.dialogStockInHistory.product_stock_data = rrr.items || []
                  }
                }
              })
          } else {
            this.dialogStockInHistory.loading = false
          }
        })
    },
    ShowPayments (stockRequestID, supplierID, shouldBePaid) {
      if (stockRequestID) {
        this.dialogPayments.id = stockRequestID
        this.dialogPayments.supplier = supplierID
        this.dialogPayments.show = true
        this.dialogPayments.loading = true
        this.dialogPayments.data = []
        this.dialogPayments.newPayment.amount = shouldBePaid || 0
        this.dialogPayments.newPayment.payment_date = this.$localDT(null, 'datedefault')
        this.LoadPayments(stockRequestID)
      }
    },
    LoadPayments (stockRequestID) {
      this.$store.dispatch('trx/TRX_PAYMENT_OUT_GET', stockRequestID)
        .then((res) => {
          this.dialogPayments.loading = false
          if (res.status) {
            this.dialogPayments.data = res.data.data || []
          }
        })
    },
    ProcessPayment () {
      if (!this.dialogPayments.newPayment.payment_date) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid Payment Date!' })
        return false
      }
      if (parseInt(this.dialogPayments.newPayment.amount) < 1000) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid Amount!' })
        return false
      }
      if (!confirm('Create new Payment ?')) {
        return false
      }
      this.dialogPayments.loading = true
      this.$store.dispatch('trx/TRX_PAYMENT_OUT_PROCESS', {
        payment_date: this.dialogPayments.newPayment.payment_date,
        created: this.dialogPayments.newPayment.payment_date,
        amount: this.dialogPayments.newPayment.amount,
        notes: this.dialogPayments.newPayment.notes,
        stock_request: this.dialogPayments.id,
        supplier: this.dialogPayments.supplier,
        payment_type: 'bank_transfer',
        status: 1
      })
        .then((res) => {
          if (res.status) {
            this.dialogPayments.data = res.data.data || []
            this.LoadPayments(this.dialogPayments.id)
            this.dialogPayments.newPayment.payment_date = null
            this.dialogPayments.newPayment.amount = 0
            this.dialogPayments.newPayment.notes = ''
          } else {
            this.dialogPayments.loading = false
          }
        })
    },
    SUPPLIER_GET () {
      this.supplierData = []
      this.$store.dispatch('product/SUPPLIER_GET')
        .then((res) => {
          if (res.status) {
            this.supplierData = res.data.data || []
          }
        })
    },
    SUPPLIER_SHOW (data) {
      if (data) {
        this.dialogSupplier.data = Object.assign({}, data)
      } else {
        this.dialogSupplier.data = {
          id: null,
          name: '',
          email: '',
          phone: '',
          address: '',
          status: 1
        }
      }
      this.dialogSupplier.show = true
    },
    SUPPLIER_PROCESS () {
      const params = Object.assign({}, this.dialogSupplier.data)
      if (!params.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid supplier name!' })
        return false
      }
      if (!this.$validateEmail(params.email)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid email!' })
        return false
      }
      if (!this.$validatePhone(params.phone)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid phone number!' })
        return false
      }
      params.phone = this.$reformatPhone(params.phone)
      this.$store.dispatch('product/SUPPLIER_PROCESS', params)
        .then((res) => {
          if (res.status) {
            this.$store.dispatch('TOAST', { show: true, message: params.id ? 'Updated!' : 'Created!' })
            this.dialogSupplier.show = false
            this.SUPPLIER_GET()
          }
        })
    },
    async PURCHASE_SHOW (purchase) {
      if (purchase) {
        this.dialogPurchase.selected = null
        await this.$store.dispatch('product/STOCK_REQUEST_GET_ONE', purchase.id)
          .then((res) => {
            if (res.status) {
              if (res.data.data) {
                this.dialogPurchase.selected = Object.assign({}, res.data.data)
                this.dialogPurchase.statusOld = parseInt(this.dialogPurchase.selected.status) || 0
              }
            }
          })
      } else {
        this.dialogPurchase.selected = {
          id: null,
          supplier: 0,
          user_requestor: this.$store.state.user ? this.$store.state.user.id : 0,
          title: '',
          payment_type: 0,
          payment_recurring: 1,
          payment_duedate: 0,
          items: [],
          status: 0
        }
      }
      this.dialogPurchase.show = true
    },
    async PURCHASE_GET (q, dataExcel) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const withSortBy = sortBy ? (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '') : ''
      const pageRow = (parseInt(page) > 0 ? '&page=' + page : '')
      const limitRow = (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '')
      const withStatus = ('&status=' + (this.tableSearchStatus > -1 ? this.tableSearchStatus : ''))
      const fromTo = this.GetDates()
      if (dataExcel) {
        const exportPage = '&page=1'
        const exportLimit = dataExcel === 'all' ? '&limit=1000000000' : limitRow
        const query = '?c=' + exportPage + exportLimit + withSortBy
        const exportQuery = dataExcel === 'all' ? '' : query + (q ? ('&q=' + q) : '') + fromTo + withStatus
        return await this.$store.dispatch('product/STOCK_REQUEST_GET', exportQuery).then(res => res)
      } else {
        const query = '?c=' + pageRow + limitRow + withSortBy
        this.tableLoading = true
        await this.$store.dispatch('product/STOCK_REQUEST_GET', query + (q ? ('&q=' + q) : '') + fromTo + withStatus).then((res) => {
          if (res.status) {
            this.table = res.data.data.data
            this.tableTotal = parseInt(res.data.data.total) || 0
          } else {
            this.table = []
            this.tableTotal = 0
          }
          this.tableLoading = false
        })
      }
    },
    GetDates () {
      const fromTo = this.dates
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return '&from=' + fromTo[0] + '&to=' + fromTo[1]
          } else {
            return '&from=' + fromTo[1] + '&to=' + fromTo[0]
          }
        }
      }
      return ''
    },
    PURCHASE_PROCESS () {
      const processedPurchase = Object.assign({}, this.dialogPurchase.selected)
      if (!parseInt(processedPurchase.supplier)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Please select supplier!' })
        return false
      }
      if (!processedPurchase.items.length) {
        this.$store.dispatch('TOAST', { show: true, message: 'No item selected!' })
        return false
      }
      if (!confirm(processedPurchase.id ? 'Update purchase ?' : 'Create new purchase ?')) {
        return false
      }
      this.$store.dispatch('product/STOCK_REQUEST_PROCESS', processedPurchase)
        .then((res) => {
          if (res.status) {
            this.PURCHASE_GET(this.tableSearch)
            this.dialogPurchase.show = false
            this.$store.dispatch('TOAST', { show: true, message: processedPurchase.id ? 'Updated!' : 'Created!' })
          } else {
            if (res.data) {
              if (res.data.response) {
                if (res.data.response.data.message) {
                  this.$store.dispatch('TOAST', { show: true, message: res.data.response.data.message || 'Something went wrong!' })
                  return false
                }
              }
            }
            this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
          }
        })
    },
    PRODUCT_GET (q) {
      this.$store.dispatch('product/PRODUCT_GET', '?q=' + (q || '') + '&page=1&limit=12&status=1&is_service=0').then((res) => {
        if (res.status) {
          this.productData.result = res.data.data.data || []
        } else {
          this.productData.result = []
        }
      })
    }
  }
}
</script>

<style lang="scss">
.outer-btn {
  .v-input__append-outer {
    margin-top: 4px!important;
  }
}
</style>
